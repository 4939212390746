const behaviours = [
	{
		name: "parallax",
		doImport: import("../components/parallax"),
	},
	{
		name: "directory",
		doImport: import("../components/directory"),
	},
];

const pageIncludesBehaviour = (behaviour) => {
	const element = document.querySelector(`[data-behaviour="${behaviour}"]`);
	return element ? true : false;
};

const loadBundlesForBehaviours = () => {
	return behaviours.forEach((behaviour) => {
		if (pageIncludesBehaviour(behaviour.name) && behaviour.doImport) {
			behaviour.doImport.then((component) => {
				const elements = [
					...document.querySelectorAll(`[data-behaviour="${behaviour.name}"]`),
				];

				elements.forEach((el) => component.render(el));
			});
		}
	});
};

export default loadBundlesForBehaviours;
