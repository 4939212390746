/**
 * Substitutive pagination with prev/next buttons
 * Based on loadMore.js
 */
const getElementScrollPosition = (localEl) => {
	const posY = localEl.children[0].getBoundingClientRect().top + window.scrollY;
	return posY - 100;
};

const scrollToElement = (localEl) => {
	setTimeout(() => {
		window.scrollTo({
			top: getElementScrollPosition(localEl),
			left: 0,
			behavior: "smooth",
		});
	}, 500);
};
class Pagination {
	constructor(el) {
		this.el = el;
		this.page = 1;

		this.paginationPrev = el.querySelector("[data-pagination-prev]");
		this.paginationNext = el.querySelector("[data-pagination-next]");
		this.localEl = el.querySelector("[data-pagination-articles]");
		this.endpoint = el.dataset.endpoint;
		this.category = el.dataset.category;
		this.tag = el.dataset.tag;

		if (!this.localEl) return;

		if (this.paginationPrev) {
			this.paginationPrev.addEventListener(
				"click",
				this.handlePaginationClick.bind(this),
			);
		}
		if (this.paginationNext) {
			this.paginationNext.addEventListener(
				"click",
				this.handlePaginationClick.bind(this),
			);
		}
	}

	handlePaginationClick(e) {
		e.preventDefault();

		if (this.paginationPrev.contains(e.target)) {
			this.page--;
		} else if (this.paginationNext.contains(e.target)) {
			this.page++;
		} else {
			// click somehow not in either button
			return;
		}

		const params = new URLSearchParams({
			page: this.page,
			category: this.category,
			tag: this.tag,
		});

		const url = "/wp-json/snap/v2/" + this.endpoint + "?" + params;

		fetch(url)
			.then((response) => response.json())
			.then((response) => {
				this.toggleActivity(this.paginationPrev, this.page === 1);
				this.toggleActivity(
					this.paginationNext,
					this.page === response.totalPages,
				);

				this.localEl.innerHTML = response.html;
				scrollToElement(this.localEl);
			})
			.catch((err) => console.log(err));
	}

	toggleActivity(button, disabled = true) {
		button.setAttribute("aria-disabled", disabled);
		button.disabled = disabled;
	}
}
export default Pagination;
