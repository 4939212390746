import resize, { minTablet } from "../helpers/resize";
import ContentRelocateSidebar from "./contentRelocateSidebar";

class StickySidebar {
	constructor(el) {
		this.relocate = new ContentRelocateSidebar(el);

		this.sidebar = el.querySelector("[data-sidebar");
		this.header = document.querySelector("[data-header]");
		this.mainContent = document.querySelector("[data-main]");
		this.items = [...el.querySelectorAll("[data-sidebar-item]")];
		this.tl = null;
		this.count = 0;
		this.tick = this.detectHeightChange.bind(this);

		if (!this.sidebar) return;

		this.sidebarHeight = el.querySelector("[data-sidebar").clientHeight;

		this.setSidebar();
		this.tick();
		resize(this.setSidebar.bind(this));
	}

	detectHeightChange() {
		setInterval(() => {
			if (this.count >= 20) {
				clearInterval(this.tick);
			} else {
				this.count = this.count + 1;

				if (this.sidebar.clientHeight !== this.sidebarHeight) {
					if (this.tl) {
						this.tl.scrollTrigger.refresh();
						clearInterval(this.tick);
					}

					this.sidebarHeight = this.sidebar.clientHeight;
				}
			}
		}, 1000);
	}

	sidebarIsTallerThanViewport() {
		let headerHeightWithoutAd = this.header.clientHeight;
		const ad = this.header.querySelector("[data-ad]");

		if (ad) {
			headerHeightWithoutAd = this.header.clientHeight - ad.clientHeight;
		}

		return (
			this.sidebar.clientHeight >
			window.innerHeight - headerHeightWithoutAd + 50
		);
	}

	destroyTimeline() {
		this.items.forEach((element) => {
			element.removeAttribute("style");
		});
	}

	setSidebarStyles() {
		let headerHeightWithoutAd = this.header.clientHeight;
		const ad = this.header.querySelector("[data-ad]");

		if (ad) {
			headerHeightWithoutAd = this.header.clientHeight - ad.clientHeight;
		}

		this.items.forEach((element) => {
			element.style.position = "sticky";
			element.style.top = `${headerHeightWithoutAd}px`;
		});
	}

	setSidebar() {
		/* Min-width = tablet: */
		if (minTablet()) {
			if (this.sidebarIsTallerThanViewport()) {
				this.setSidebarStyles();
			}
		}

		/* Mobile: */
		if (!minTablet()) {
			this.destroyTimeline();
		}
	}
}

export default StickySidebar;
