class Tabs {
	constructor(el) {
		this.el = el;
		this.tabButtonsGroup = el.querySelector("[data-tab-buttons]");
		this.tabButtons = [...el.querySelectorAll("[data-tab-button]")];
		this.tabPanels = [...el.querySelectorAll("[data-tabpanel]")];
		this.currentTabIndex = 0;
		this.shiftKeyDown = false;
		this.clickHandler = this.handleClick.bind(this);
		this.keyDownHandler = this.handleKeyDown.bind(this);
		this.keyUpHandler = this.handleKeyUp.bind(this);
		this.tabPanelKeyDownHandler = this.handleTabPanelKeyDown.bind(this);

		this.addEvents();
		this.setActivePanel();
		this.setActiveTabButton();
	}

	addEvents() {
		this.tabButtonsGroup.addEventListener("click", this.clickHandler);
		this.el.addEventListener("keydown", this.keyDownHandler);
		this.el.addEventListener("keyup", this.keyUpHandler);
		this.tabPanels.forEach((el) => {
			el.addEventListener("keydown", this.tabPanelKeyDownHandler);
		});
	}

	removeEvents() {
		this.tabButtonsGroup.removeEventListener("click", this.clickHandler);
		this.el.removeEventListener("keydown", this.keyDownHandler);
	}

	setActivePanel() {
		this.tabPanels.forEach((el, index) => {
			if (this.currentTabIndex === index) {
				el.hidden = false;
				el.setAttribute("tabindex", 0);
			} else {
				el.hidden = true;
				el.setAttribute("tabindex", -1);
			}
		});
	}

	setActiveTabButton() {
		this.tabButtons.forEach((el, index) => {
			if (this.currentTabIndex === index) {
				el.setAttribute("aria-selected", true);
				el.classList.add("is-selected");
			} else {
				el.removeAttribute("aria-selected");
				el.classList.remove("is-selected");
			}
		});
	}

	handleClick(e) {
		e.preventDefault();
		if (!this.tabButtons.includes(e.target)) return;

		this.currentTabIndex = parseInt(e.target.dataset.tabButton);
		this.setActivePanel();
		this.setActiveTabButton();
	}

	goToPanel(nextTabIndex) {
		this.currentTabIndex = nextTabIndex;
		this.setActivePanel();
		this.setActiveTabButton();
		this.tabButtons[this.currentTabIndex].focus();
	}

	handleKeyDown(e) {
		if (e.code === "ShiftLeft" || e.code === "ShiftRight") {
			this.shiftKeyDown = true;
		}

		if (e.code === "ArrowRight") {
			const nextTabIndex =
				this.currentTabIndex < this.tabPanels.length - 1
					? this.currentTabIndex + 1
					: 0;
			e.preventDefault();
			this.goToPanel(nextTabIndex);
		}

		if (e.code === "ArrowLeft") {
			const prevTabIndex =
				this.currentTabIndex > 0
					? this.currentTabIndex - 1
					: this.tabPanels.length - 1;

			e.preventDefault();
			this.goToPanel(prevTabIndex);
		}

		if (e.code === "Tab" && !this.shiftKeyDown) {
			this.focusTabPanel(e);
		}
	}

	focusTabPanel(e) {
		if (e.target.dataset.tabButton) {
			e.preventDefault();
			this.tabPanels[this.currentTabIndex].focus();
		}
	}

	handleKeyUp(e) {
		if (e.code === "ShiftLeft" || e.code === "ShiftRight") {
			this.shiftKeyDown = false;
		}
	}

	handleTabPanelKeyDown(e) {
		if (e.code === "Tab" && this.shiftKeyDown) {
			e.preventDefault();
			this.tabButtons[this.currentTabIndex].focus();
		}
	}
}

export default Tabs;
