import throttle from "lodash.throttle";

const resize = (callback, wait = 600) => {
	window.addEventListener("resize", throttle(callback, wait));
};

const isDesktop = () => window.matchMedia("(min-width: 1024px)").matches;
const minTablet = () => window.matchMedia("(min-width: 768px)").matches;

export { isDesktop, minTablet };
export default resize;
