export const initialize = (selectorString, component) => {
	const elements = [
		...document.querySelectorAll(`[data-behaviour=${selectorString}]`),
	];

	return elements.forEach((el) => new component(el));
};

export const initializeWithClass = (selectorString, component) => {
	const instances = [...document.querySelectorAll(selectorString)];

	instances.forEach((el) => new component(el));
};
