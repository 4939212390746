/* Float sanitizer */
const elementContainsFloatClass = (el) => {
	if (el.classList.contains("alignright")) return true;
	if (el.classList.contains("alignleft")) return true;
	return false;
};

const blockHasFloatedChildren = (block) => {
	return [...block.children].some((el) => elementContainsFloatClass(el));
};

const isFloated = (block) => {
	if (elementContainsFloatClass(block)) return true;
	if (blockHasFloatedChildren(block)) return true;

	return false;
};

const gutenbergFloatSanitizer = () => {
	const gbContent = document.querySelector(".entry-content");

	if (!gbContent) return;

	if (gbContent.children.length == 0) return;

	const firstBlock = gbContent.children[0];

	/*
    Check if first element is floated or has floated children
    and add class to the block
  */
	if (isFloated(firstBlock)) {
		firstBlock.classList.add("is-floated-block");
	}
};

export default gutenbergFloatSanitizer;
