function liveSearch() {
	return {
		text: "",
		matches: true,

		shouldShowItem(item) {
			const re = new RegExp(this.text, "gmi");

			return item.querySelector("h4").innerText.match(re);
		},

		updateResults(refs) {
			const items = [...refs.list.children];

			this.matches = items.some((item) => this.shouldShowItem(item));

			items.forEach((item) => {
				if (this.shouldShowItem(item)) {
					item.hidden = false;
				} else {
					item.hidden = true;
				}
			});
		},
	};
}

export default liveSearch;
