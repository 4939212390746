import resize from "../helpers/resize";

class FilterCategories {
	constructor(el) {
		this.el = el;
		this.content = el.querySelector("[data-content]");
		this.button = el.querySelector("[data-btn]");
		this.category = el.querySelector("[data-category]");
		this.linksInContent = this.content.querySelectorAll(
			"a[href]:not([disabled]), button:not([disabled]",
		);
		this.open = false;

		this.setAttributes();
		this.button.addEventListener("click", this.handleClick.bind(this));
		resize(this.setAttributes.bind(this));
	}

	updateCategory(categoryName) {
		this.category.innerText = categoryName;
	}

	setAttributes() {
		if (window.innerWidth < 768 && !this.open) {
			this.closeContent();
		} else {
			this.openContent();
		}
	}

	openContent() {
		this.content.hidden = false;
		this.button.setAttribute("aria-expanded", false);
		this.linksInContent.forEach((el) => {
			el.setAttribute("tabindex", 0);
		});
	}

	closeContent() {
		if (window.innerWidth < 768) {
			this.content.hidden = true;
			this.button.setAttribute("aria-expanded", true);
			this.linksInContent.forEach((el) => {
				el.setAttribute("tabindex", -1);
			});
		}
	}

	handleClick() {
		if (!this.open) {
			this.openContent();
			this.open = true;
		} else {
			this.closeContent();
			this.open = false;
		}
	}
}

export default FilterCategories;
