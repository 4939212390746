/**
 * Additive pagination with "load more" button
 */
let prevLength = document.querySelector("[data-all-articles]")
	? document.querySelector("[data-all-articles]").children.length
	: 0;

const getElementScrollPosition = (localEl) => {
	const posY =
		localEl.children[prevLength].getBoundingClientRect().top + window.scrollY;
	return posY - 100;
};

const scrollToElement = (localEl) => {
	const newElements = [...localEl.children].filter(
		(el, index) => index > prevLength,
	);

	newElements.forEach((el) => {
		el.style.opacity = 0.5;
	});

	setTimeout(() => {
		window.scrollTo({
			top: getElementScrollPosition(localEl),
			left: 0,
			behavior: "smooth",
		});

		newElements.forEach((el) => {
			el.style.opacity = 1;
		});

		newElements[0].focus();
		prevLength = localEl.children.length;
	}, 500);
};
class LoadMore {
	constructor(el) {
		this.el = el;
		this.paginationButton = el.querySelector("[data-load-more-button]");
		this.localEl = el.querySelector("[data-all-articles]");

		if (!this.localEl) return;

		if (this.paginationButton) {
			this.paginationButton.addEventListener(
				"click",
				this.handlePaginationClick.bind(this),
			);
		}
	}

	setButtonAttributes(page) {
		this.paginationButton.dataset.page = page;
		this.paginationButton.textContent = "Load more";
		this.paginationButton.disabled = false;
	}

	handlePaginationClick(e) {
		e.preventDefault();
		const endpoint = this.el.dataset.section;
		const company = this.paginationButton.dataset.company;
		const page = this.paginationButton.dataset.page;

		this.paginationButton.textContent = "Loading...";
		this.paginationButton.disabled = true;

		const params = new URLSearchParams({
			directory: company,
			page: page,
		});

		const url = "/wp-json/snap/v2/" + endpoint + "?" + params;

		// console.log(url)

		fetch(url)
			.then((response) => response.json())
			.then((response) => {
				// console.log(response)

				const page = response.currentPage;

				this.localEl.innerHTML = this.localEl.innerHTML + response.html;

				if (response.totalPages > page) {
					this.setButtonAttributes(page + 1);
					scrollToElement(this.localEl);
				} else {
					this.paginationButton.remove();
				}
			})
			.catch((err) => console.log(err));
	}
}
export default LoadMore;
