import { isDesktop } from "../helpers/resize";
import { trapFocus } from "../helpers/trapFocus";

function submenu() {
	return {
		show: false,
		toggle() {
			this.show = !this.show;
		},
		resize() {
			this.show = isDesktop();
		},
		trapFocusInMenu(e, el) {
			if (!isDesktop()) {
				trapFocus(e, el);
			}
		},
		isOpen() {
			return this.show;
		},
	};
}

export default submenu;
