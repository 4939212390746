class ScrollToElement {
	constructor(el) {
		this.el = el;
		this.buttons = [...el.querySelectorAll('[data-btn="scroll-to"]')];

		this.buttons.forEach((el) => {
			el.addEventListener("click", this.handleClick.bind(this));
		});
	}

	scrollYPosition(targetEl) {
		if (!targetEl) return;
		const headerHeight = document.querySelector("[data-header]").clientHeight;
		const targetY = window.pageYOffset + targetEl.getBoundingClientRect().top;

		return targetY - headerHeight - 50;
	}

	scrollToTarget(e, targetEl, wait = 600) {
		e.preventDefault();

		window.scrollTo({
			top: this.scrollYPosition(targetEl),
			left: 0,
			behavior: "smooth",
		});

		setTimeout(() => targetEl.focus(), wait);
	}

	handleClick(e) {
		const id = e.target.getAttribute("href");
		const targetEl = document.querySelector(id);

		this.scrollToTarget(e, targetEl);
	}
}

export default ScrollToElement;
