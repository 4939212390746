import bodyScrollLock from "../helpers/bodyScrollLock";
import { isDesktop } from "../helpers/resize";
import { trapFocus } from "../helpers/trapFocus";

function menu() {
	return {
		showMenu: false,
		activeSubmenu: null,
		scrollY: false,
		showTopNav: true,
		direction: "down",
		initialTopNavHeight: 0,
		topNav: null,
		headerEl: null,
		bannerAd: null,
		menuContent: null,
		prevSize: 0,

		toggleMenu(el, event) {
			this.setMenuContentHeight(el);
			event.target.focus();

			if (!this.showMenu) {
				const posY = this.headerEl.getBoundingClientRect().top;

				this.headerEl.style.position = "fixed";
				this.headerEl.style.top = `${posY}px`;
				this.main.style.paddingTop = `${
					this.headerEl.clientHeight + this.headerEl.getBoundingClientRect().top
				}px`;

				setTimeout(() => {
					document.body.style.overflow = "hidden";
				}, 400);
			} else {
				document.body.style.overflow = "auto";
				this.headerEl.style.position = "sticky";
				this.main.style.paddingTop = "";
				el.removeAttribute("style");
			}

			setTimeout(() => {
				this.showMenu = !this.showMenu;
			}, 100);
		},

		bannerAdIsScrolled() {
			return this.bannerAd && this.bannerAd.getBoundingClientRect().bottom <= 0;
		},

		setMenuStyles(menuContentPosY) {
			this.menuContent.style.top = `${menuContentPosY}px`;
			this.menuContent.style.height = `${
				window.innerHeight - menuContentPosY
			}px`;
		},

		setMenuContentHeight(el) {
			const menuContentPosY = el.getBoundingClientRect().bottom;

			if (!this.bannerAd) {
				return this.setMenuStyles(menuContentPosY);
			}

			this.menuContent.style.top = `${el.offsetHeight}px`;
			this.menuContent.style.height = `${
				window.innerHeight - el.getBoundingClientRect().bottom
			}px`;
		},

		toggleSubmenu(e) {
			if (this.isSubmenuOpen(e.target.dataset.index)) {
				this.activeSubmenu = null;
			} else {
				this.activeSubmenu = parseInt(e.target.dataset.index);
			}
		},

		init(el, refs) {
			const { topNav, menuContent } = refs;

			this.headerEl = document.querySelector("[data-header]");
			this.bannerAd = this.headerEl.querySelector("[data-ad]");
			this.main = document.querySelector("#main");
			this.menuContent = menuContent;
			this.topNav = topNav;
			this.prevSize = window.innerWidth;

			this.resize();
		},

		shouldCloseMenu() {
			/* On Android the resize event fires when the search input is focused. Prevent the menu closing on focus by only hiding the menu when the size difference is > 50px */
			if (this.prevSize < window.innerWidth - 50) return true;
			if (this.prevSize > window.innerWidth + 50) return true;
			return false;
		},

		resize() {
			if (isDesktop()) {
				this.showMenu = true;
				this.initialTopNavHeight = this.topNav.children[0].offsetHeight + 20;
				this.menuContent.style = "";
				bodyScrollLock(false);
			} else if (this.shouldCloseMenu()) {
				this.showMenu = false;
			}

			this.prevSize = window.innerWidth;
		},

		menuTrapFocus(e, el) {
			if (window.innerWidth < 1024) {
				trapFocus(e, el);
			}
		},

		isOpen() {
			return this.showMenu === true;
		},

		activeSubmenuIndex() {
			return this.activeSubmenu;
		},

		isSubmenuOpen(index) {
			return this.activeSubmenu === parseInt(index);
		},
	};
}

export default menu;
