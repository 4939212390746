import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { minTablet } from "../helpers/resize";

gsap.registerPlugin(ScrollTrigger);

class CardsFadeIn {
	constructor(el) {
		this.el = el;
		this.items = [...document.querySelectorAll("[data-item]")];

		console.log(this.container);

		gsap.set("[data-item]", {
			opacity: 0,
			scale: 0.7,
		});

		this.items.forEach((element, index) =>
			this.initialiseTimeline(element, index),
		);
	}

	calculateDelay(index) {
		if (minTablet) {
			return index % 2 === 0 ? 0 : 0.3;
		} else {
			return 0;
		}
	}

	doConfetti(confettiElement, i) {
		const delay = this.calculateDelay(i) * 100;
		confettiElement.classList.add("is-visible");

		setTimeout(function () {
			// Remove confetti when animation finished
			confettiElement.addEventListener(
				"animationend",
				() => {
					confettiElement.classList.remove("is-visible");
				},
				{ once: true },
			);
		}, delay);
	}

	initialiseTimeline(element, index) {
		const confettiElement = element.querySelector(".confetti");

		const timeline = gsap.timeline({
			scrollTrigger: {
				start: "top 90%",
				end: "bottom 10%",
				toggleActions: "play revers play reverse",
				// markers: true,
				trigger: element,
				onEnter: () => this.doConfetti(confettiElement, index),
			},
		});
		timeline.to(element, {
			opacity: 1,
			delay: this.calculateDelay(index),
			duration: 0.3,
			scale: 1,
		});
	}
}

export default CardsFadeIn;
