import "regenerator-runtime/runtime.js";
import "alpinejs";
import "focus-visible";
import "default-passive-events";

/* Import components as needed */
import Accordion from "./components/accordion";
import AddToCalendar from "./components/addToCalendar";
import AjaxTab from "./components/ajaxTab";
import Card from "./components/card";
import CardsFadeIn from "./components/cardsFadeIn";
import ChatBot from "./components/chatBot";
import ContentRelocate from "./components/contentRelocate";
import EventsFilter from "./components/eventsFilter";
import filterAccordion from "./components/filterAccordion";
import FilterCategories from "./components/filterCategories";
import Header from "./components/header";
import JumpNav from "./components/jumpNav";
import LightboxForm from "./components/lightboxForm";
import liveSearch from "./components/liveSearch";
import LoadMore from "./components/loadMore";
import menu from "./components/menu";
import Pagination from "./components/pagination";
import popup from "./components/popup";
import ScrollProgress from "./components/scrollProgress";
import ScrollToElement from "./components/scrollToElement";
import SearchPosition from "./components/searchPosition";
import StickySidebar from "./components/stickySidebar";
import submenu from "./components/submenu";
import Tabs from "./components/tabs";
import gutenbergFloatSanitizer from "./helpers/gutenbergFloatSanitizer";
import iframeResizer from "./helpers/iframeResizer";
import { initialize } from "./helpers/init";
import loadBundlesForBehaviours from "./helpers/loadBundles";

document.body.classList.remove("no-js");

const initCardsFadeIn = () => {
	if (
		"IntersectionObserver" in window &&
		"IntersectionObserverEntry" in window &&
		"intersectionRatio" in window.IntersectionObserverEntry.prototype
	) {
		document.body.classList.add("has-intersect");
		initialize("cards-fade-in", CardsFadeIn);
	} else {
		document.body.classList.remove("has-intersect");
		document.body.classList.add("no-observer");
	}
};

initCardsFadeIn();

/* Initialize components as needed */
initialize("accordion", Accordion);
initialize("filter-categories", FilterCategories);
initialize("tabs", Tabs);
initialize("cal", AddToCalendar);
initialize("scroll-to", ScrollToElement);
initialize("ajax-tab", AjaxTab);
initialize("card", Card);
initialize("header", Header);
initialize("pagination", Pagination);
initialize("load-more", LoadMore);
initialize("search-position", SearchPosition);
initialize("sticky-sidebar", StickySidebar);
initialize("lightbox-form", LightboxForm);
initialize("jump-nav", JumpNav);
initialize("content-relocate", ContentRelocate);
initialize("events-filter", EventsFilter);
initialize("scroll-progress", ScrollProgress);

/* Alpine JS */
window.menu = menu;
window.submenu = submenu;
window.filterAccordion = filterAccordion;
window.popup = popup;
window.liveSearch = liveSearch;

/* Conditionally load bundles */
const loadBundles = () => {
	const galleries = [
		...document.querySelectorAll(".wp-block-gallery"),
		...document.querySelectorAll("[data-gallery]"),
		...document.querySelectorAll(".wp-block-image"),
	];

	if (document.querySelector("#app")) {
		import("./vue/events/app.js").then((vueApp) => vueApp.render());
	}

	if (document.querySelector("#speaker-filter")) {
		import("./vue/speaker-filter/speakerFilter.js").then((speakerFilter) =>
			speakerFilter.render(),
		);
	}

	if (galleries.length) {
		import("./components/gallery").then((component) => {
			galleries.forEach((el) => component.render(el));
		});
	}

	if (document.querySelector(".share-btn")) {
		import("share-buttons").then(() => console.log("share"));
	}
};

loadBundles();
loadBundlesForBehaviours();
iframeResizer();
gutenbergFloatSanitizer();

// Initialize ChatBot
const chatBotElement = document.querySelector("df-messenger");
if (chatBotElement) {
	new ChatBot(chatBotElement);
}
