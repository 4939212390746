import resize, { isDesktop } from "../helpers/resize";

const classesToAppend = ["pt-2", "pb-4", "border-t-4"];

class SearchPosition {
	constructor(el) {
		this.section = el.querySelector('[data-section="0"]');
		this.searchModule = el.querySelector("[data-search]");
		this.sidebar = el.querySelector("[data-sidebar]");
		this.isAppended = false;

		this.appendSearch();
		resize(this.appendSearch.bind(this));
	}

	appendSearch() {
		if (!this.section || !this.searchModule) return;
		if (isDesktop() && !this.isAppended) return;
		if (!isDesktop() && this.isAppended) return;

		if (!isDesktop()) {
			this.appendToFirstSection();
		} else {
			this.appendToSidebar();
		}
	}

	appendToFirstSection() {
		this.section.appendChild(this.searchModule);
		this.searchModule.classList.add(...classesToAppend);
		this.isAppended = true;
	}

	appendToSidebar() {
		if (this.sidebar.children) {
			this.sidebar.insertBefore(this.searchModule, this.sidebar.children[0]);
		} else {
			this.sidebar.appendChild(this.searchModule);
		}

		this.searchModule.classList.remove(...classesToAppend);
		this.isAppended = false;
	}
}

export default SearchPosition;
