class ChatBot {
	constructor(el) {
		this.el = el;
		if (!el) return;

		if (window.sessionStorage.getItem("chatBotPromptClosed")) {
			return;
		}

		// Hide the banner when the chat is opened
		window.addEventListener("df-chat-open-changed", (event) => {
			if (event.detail.isOpen) {
				this.el.classList.remove("show-banner");
			}
		});

		this.showBanner();
		this.el
			.querySelector("[data-close]")
			.addEventListener("click", this.closeBanner.bind(this));
	}

	showBanner() {
		setTimeout(() => {
			this.el.classList.add("show-banner");
		}, 1000);
	}

	closeBanner() {
		window.sessionStorage.setItem("chatBotPromptClosed", true);
		this.el.classList.remove("show-banner");
	}
}

export default ChatBot;
