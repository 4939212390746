import bodyScrollLock from "../helpers/bodyScrollLock";
import { trapFocus } from "../helpers/trapFocus";

function popup() {
	return {
		dateLastShown: null,
		show: false,
		shouldTriggerPopup: false,

		init(el, refs) {
			if (this.hasHiddenPopup()) {
				return;
			}

			const { content } = refs;
			const storedDate =
				window.sessionStorage.getItem("popupLastShown") ||
				window.localStorage.getItem("popupLastShown");

			const todaysDate = Date.now();
			const monthInMilliseconds = 2592000000;

			if (storedDate > todaysDate - monthInMilliseconds) {
				this.dateLastShown = storedDate;
				this.shouldTriggerPopup = false;
			} else {
				this.triggered = false;
				this.shouldTriggerPopup = true;
				addEventListener(
					"pointermove",
					() => {
						console.log("pointermove listener");

						this.openPopup(el, content);
					},
					{ once: true },
				);
				addEventListener(
					"keydown",
					() => {
						console.log("keydown listener");

						this.openPopup(el, content);
					},
					{ once: true },
				);
				addEventListener(
					"scroll",
					() => {
						console.log("scroll listener");
						this.openPopup(el, content);
					},
					{ once: true },
				);
			}
		},

		// Check cookie
		hasHiddenPopup() {
			return document.cookie
				.split(";")
				.some((item) => item.trim().startsWith("hidePopup="));
		},

		trapFocusInPopup(e, el) {
			if (this.show) {
				trapFocus(e, el);
			}
		},

		openStyles(el) {
			el.hidden = false;
			el.classList.remove("hidden");
			el.setAttribute("aria-hidden", false);
			bodyScrollLock(true, window.scrollY);

			setTimeout(() => {
				el.classList.add("is-active");
				el.style.opacity = 1;
			}, 100);
		},

		focusContent(content) {
			content.setAttribute("tabindex", 0);
			content.focus();
		},

		openPopup(el, content) {
			const timeout = parseInt(el.dataset.timeout) || 5000;

			if (this.triggered) {
				return;
			}

			this.triggered = true;

			setTimeout(() => {
				this.show = true;
				this.dateLastShown = Date.now();
				window.localStorage.setItem("popupLastShown", this.dateLastShown);
				this.openStyles(el);
				this.focusContent(content);
			}, timeout);
		},

		closePopup(el, event, refs) {
			if (!event.target.dataset) return;
			if (!event.target.dataset.popupClose) return;

			this.show = false;
			el.style.opacity = 0;
			refs.content.setAttribute("tabindex", -1);
			bodyScrollLock(false, window.scrollY);

			setTimeout(() => {
				el.setAttribute("aria-hidden", true);
				el.hidden = true;
			}, 300);
		},

		isOpen() {
			return this.show === true;
		},
	};
}

export default popup;
