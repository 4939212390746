import { minTablet } from "../helpers/resize";

function filterAccordion() {
	return {
		open: false,
		windowHeight: 0,
		isPositioned: false,

		transitionUp(el, refs) {
			const { accordion, button } = refs;
			const t = accordion.getBoundingClientRect().top - button.clientHeight;

			if (!this.open && !minTablet()) {
				el.style.transform = `translate3d(0, -${t}px, 0)`;
				accordion.setAttribute("tabindex", "0");
			} else {
				el.style.transform = `translate3d(0, 0, 0)`;
				accordion.setAttribute("tabindex", "-1");
			}
		},

		positionAccordion(el, refs) {
			if (minTablet() && !this.isPositioned) return;
			if (this.open) return;

			this.windowHeight = window.innerHeight;

			if (minTablet()) {
				el.removeAttribute("style");
				refs.accordion.removeAttribute("style");
				this.isPositioned = false;
			} else {
				const buttonHeight = refs.button.clientHeight;
				const h = `${this.windowHeight - buttonHeight}px`;

				el.style.top = h;
				refs.accordion.style.minHeight = h;
				this.open = false;
				this.isPositioned = true;
			}
		},

		buttonText() {
			return this.open ? "Close category list" : "Filter by category";
		},

		onClick(el, refs) {
			this.transitionUp(el, refs);
			this.open = !this.open;
		},
	};
}

export default filterAccordion;
