import resize, { isDesktop } from "../helpers/resize";

class ContentRelocateSidebar {
	constructor(el) {
		this.el = el;
		this.ads = [...el.querySelectorAll("[data-sidebar-ad]")];

		this.contentAreaMobile = el.querySelector(
			'[data-ad-content-area="mobile"]',
		);
		if (this.contentAreaMobile === null) {
			return;
		}

		this.mobilePositions = this.calculateMobilePositions();

		this.contentAreaDesktop = el.querySelector("[data-sidebar]");
		this.ads.forEach((element, index) =>
			this.setDesktopPositions(element, index),
		);

		this.isPositionedMobile = false;
		this.positionContent();
		resize(this.positionContent.bind(this));
	}

	/**
	 * Calculate indices of main content area child elements where ads will be positioned
	 * @returns {array} Indices of child nodes
	 */
	calculateMobilePositions() {
		const adCount = this.ads.length;
		const posCount = this.contentAreaMobile.children.length;

		let step = Math.floor(posCount / adCount);

		// Set minimum number of elements between ads
		if (step < 2) {
			step = 2;
		}

		let n = 1;
		const output = [];

		while (n * step <= posCount) {
			output[n] = n * step;
			n++;
		}

		return output;
	}

	positionContent() {
		if (this.shouldPositionDesktop()) {
			this.ads.forEach((element, index) =>
				this.postitionAdDesktop(element, index),
			);
			this.isPositionedMobile = false;
		} else if (this.shouldPositionMobile()) {
			this.ads.forEach((element, index) =>
				this.positionAdMobile(element, index),
			);
			this.isPositionedMobile = true;
		}
	}

	/**
	 * Move ad into body context
	 */
	positionAdMobile(element, index) {
		const childIndex = this.mobilePositions[index + 1];

		// Hide any elements that can't fit into the body content
		if (typeof this.contentAreaMobile.children[childIndex] === "undefined") {
			element.hidden = true;
			return;
		}

		// Don't append ad to another ad
		if (
			this.contentAreaMobile.children[childIndex].classList.contains(
				"c-adbutler-ad__wrapper--video",
			)
		) {
			element.hidden = true;
			return;
		}

		this.contentAreaMobile.children[childIndex].appendChild(element);
	}

	/**
	 * Put ads back in their sidebar parent containers
	 */
	postitionAdDesktop(element, index) {
		// If element is hidden it was not moved, so just show it
		if (element.hidden === true) {
			element.hidden = false;
			return;
		}

		this.contentAreaDesktop
			.querySelector(`[data-ad-element="${index}"]`)
			.appendChild(element);
	}

	shouldPositionDesktop() {
		return isDesktop() && this.isPositionedMobile;
	}

	shouldPositionMobile() {
		return !isDesktop() && !this.isPositionedMobile;
	}

	setDesktopPositions(element, index) {
		element.parentElement.dataset.adElement = index;
	}
}

export default ContentRelocateSidebar;
