const bodyScrollLock = (expanded) => {
	const scrollY = document.body.style.top;

	if (expanded) {
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = "fixed";
	} else {
		document.body.style.position = "";
		document.body.style.top = "";
		window.scrollTo({
			top: parseInt(scrollY || "0") * -1,
			left: 0,
			behavior: "auto",
		});
	}
};

export default bodyScrollLock;
