import FilterCategories from "./filterCategories";
import ScrollToElement from "./scrollToElement";

class Ajaxtab {
	constructor(container) {
		this.container = container;
		this.filterEl = container.querySelector("[data-filter]");
		this.buttons = container.querySelectorAll("[data-ajaxtab-button]");
		this.loader = container.querySelector("[data-loader]");
		this.heading = container.querySelector("[data-tab-heading]");
		this.tabcache = [];
		this.scroller = new ScrollToElement(this.container);
		this.filter = new FilterCategories(this.filterEl);

		if (this.buttons) {
			this.initialiseButtons();
		}
	}

	initialiseButtons() {
		const timestamp = new Date().getTime();

		this.buttons.forEach((el, index) => {
			el.dataset.id = index + "_" + timestamp;
			el.addEventListener("click", this.handleClick.bind(this));
		});

		const currentlyActive = this.container.querySelector("[data-activetab]");
		if (currentlyActive) {
			this.setActive(currentlyActive, false);
			this.cacheTabContent(currentlyActive);
		}
	}

	cacheTabContent(button, htmlContent) {
		if (!htmlContent) {
			htmlContent = this.container.querySelector(
				"[data-ajaxtab-content]",
			).innerHTML;
		}

		this.tabcache[button.dataset.id] = htmlContent;
	}

	getHTML(url, callback) {
		if (!window.XMLHttpRequest) {
			return;
		}

		const xhr = new XMLHttpRequest();

		xhr.onload = function () {
			if (callback && typeof callback === "function") {
				callback(this.responseXML);
			}
		};

		xhr.open("GET", url);
		xhr.responseType = "document";
		xhr.send();
	}

	setActive(button, resetAll = true) {
		if (resetAll) {
			this.buttons.forEach((el) => {
				el.classList.remove("is-current");
				el.dataset.activetab = false;
			});
		}

		button.classList.add("is-current");
		button.dataset.activetab = true;
		this.container.dataset.activetab = button.dataset.id;
	}

	initialiseTabContent(button, tab, tabContent, remoteHeading, e) {
		/*
      check that the activetab tab actually matches the passed button id
      in cases where the user has quickly clicked another tab we
      might not want to update the tab content
    */
		if (this.container.dataset.activetab !== button.dataset.id) {
			return;
		}

		if (!tabContent) {
			tab.innerHTML = '<p class="my-4">No items found</p>';
			this.container.dataset.loading = "false";
		}

		tab.innerHTML = tabContent;
		tab.classList.remove("opacity-50");
		tab.classList.add("opacity-100");
		this.container.dataset.loading = "false";
		this.loader.hidden = true;

		if (remoteHeading) {
			this.heading.innerText = remoteHeading.innerText;
		}

		this.scrollToContent(e);
	}

	scrollToContent(e) {
		if (window.innerWidth >= 1024) return;
		const articles = this.container.querySelector("[data-ajaxtab-content]");

		this.scroller.scrollToTarget(e, articles);
	}

	handleClick(e) {
		e.preventDefault();
		const button = e.target;

		if (button.dataset.activetab === "true") {
			return; // active tab, do nothing
		}

		this.setActive(button);
		const localTab = this.container.querySelector("[data-ajaxtab-content]");

		localTab.innerHTML = "";
		this.loader.hidden = false;
		this.container.dataset.loading = "true";
		localTab.classList.add("opacity-50");
		localTab.classList.remove("opacity-100");
		this.filter.closeContent();
		this.filter.updateCategory(button.dataset.title);

		// check for cached tab content
		if (typeof this.tabcache[button.dataset.id] !== "undefined") {
			// simulate a load, but actually just show a spinner for a short time
			setTimeout(
				function () {
					this.initialiseTabContent(
						button,
						localTab,
						this.tabcache[button.dataset.id],
						null,
						e,
					);
				}.bind(this),
				300,
			);
		} else {
			// get tab content via ajax
			this.getHTML(
				button.href,
				function (response) {
					const remoteHeading = response.querySelector("[data-tab-heading]");
					const remoteTab = response.querySelector("[data-ajaxtab-content]");

					if (!remoteTab) {
						return;
					}

					this.cacheTabContent(button, remoteTab.innerHTML);
					this.initialiseTabContent(
						button,
						localTab,
						remoteTab.innerHTML,
						remoteHeading,
						e,
					);
				}.bind(this),
			);
		}
	}
}

export default Ajaxtab;
