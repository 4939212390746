class ScrollProgress {
	constructor(el) {
		this.scrollProgressBar = el.querySelector("[data-progress-bar]");

		window.onscroll = () => {
			this.updateProgress();
		};
	}

	updateProgress() {
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;
		const height =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;
		const scrolled = (winScroll / height) * 100;
		this.scrollProgressBar.style.width = scrolled + "%";
	}
}

export default ScrollProgress;
