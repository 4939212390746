import Add2Calendar from "add2calendar";

class AddToCalendar {
	constructor(el) {
		this.el = el;
		this.open = false;
		this.startDate = el.dataset.start.length ? el.dataset.start : null;
		this.button = el.querySelector("[data-button]");

		if (!el.dataset.start.length) return;

		this.endDate = el.dataset.end.length ? el.dataset.end : this.startDate;

		this.event = new Add2Calendar({
			title: el.dataset.title || "Event",
			start: this.startDate,
			end: this.endDate,
			timezone: el.dataset.timezone || "",
			isAllDay: el.dataset.allDay || "",
			location: el.dataset.location || "",
			description: el.dataset.description || "",
		});

		this.event.createWidget("[data-options]");
		this.addButton();
	}

	toggleOpen(btn, list) {
		if (this.open) {
			btn.setAttribute("aria-expanded", false);
			list.hidden = true;
			this.open = false;
		} else {
			btn.setAttribute("aria-expanded", true);
			list.hidden = false;
			this.open = true;
		}
	}

	addButton() {
		const parent = this.el.querySelector(".a2cldr");
		const span = this.el.querySelector(".a2cldr-btn");
		const list = this.el.querySelector(".a2cldr-list");

		this.button.classList.add("a2cldr-btn");
		list.classList.add("absolute");
		this.button.setAttribute("aria-expanded", false);

		list.hidden = true;
		this.button.addEventListener("click", (e) =>
			this.toggleOpen(e.target, list),
		);

		parent.replaceChild(this.button, span);
	}
}

export default AddToCalendar;
