import resize, { isDesktop, minTablet } from "../helpers/resize";

class ContentRelocate {
	constructor(el) {
		this.el = el;
		this.content = el.querySelector("[data-relocate-content]");
		this.size = el.dataset.size;
		this.contentAreaMobile = el.querySelector('[data-content-area="mobile"]');
		this.contentAreaDesktop = el.querySelector('[data-content-area="desktop"]');
		this.isPositionedMobile = false;
		if (this.size === "tablet") {
			this.positionContentTablet();
			resize(this.positionContentTablet.bind(this));
		} else {
			this.positionContent();
			resize(this.positionContent.bind(this));
		}
	}

	shouldPositionDesktop() {
		return isDesktop() && this.isPositionedMobile;
	}

	shouldPositionMobileFromDesktop() {
		return !isDesktop() && !this.isPositionedMobile;
	}

	shouldPositionTablet() {
		return minTablet() && this.isPositionedMobile;
	}

	shouldPositionMobileFromTablet() {
		return !minTablet() && !this.isPositionedMobile;
	}

	positionContent() {
		if (this.shouldPositionDesktop()) {
			this.contentAreaDesktop.appendChild(this.content);
			this.isPositionedMobile = false;
		} else if (this.shouldPositionMobileFromDesktop()) {
			this.contentAreaMobile.appendChild(this.content);
			this.isPositionedMobile = true;
		}
	}

	positionContentTablet() {
		if (this.shouldPositionTablet()) {
			this.contentAreaDesktop.appendChild(this.content);
			this.isPositionedMobile = false;
		} else if (this.shouldPositionMobileFromTablet()) {
			this.contentAreaMobile.appendChild(this.content);
			this.isPositionedMobile = true;
		}
	}
}

export default ContentRelocate;
