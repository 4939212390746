import resize from "../helpers/resize";

class Accordion {
	constructor(el) {
		this.el = el;
		this.sections = [...el.querySelectorAll("[data-accordion-section]")];
		this.buttons = [...el.querySelectorAll("[data-btn]")];
		this.contentSections = [...el.querySelectorAll("[data-content]")];
		this.isVariant = el.dataset.variant === "true";

		this.contentSections.forEach((el) => this.setContentSection(el));
		this.el.addEventListener("click", this.handleClick.bind(this));

		resize(this.handleResize.bind(this));
	}

	toggleActiveClass(button) {
		if (button.classList.contains("is-expanded")) {
			button.classList.remove("is-expanded");
		} else {
			button.classList.add("is-expanded");
		}
	}

	handleClick(e) {
		const button = e.target;
		const i = button.dataset.btn;
		if (i == undefined) {
			return;
		}
		const content = this.contentSections[i];
		let isExpanded = button.getAttribute("aria-expanded") === "true" || false;

		this.toggleActiveClass(button);
		button.setAttribute("aria-expanded", !isExpanded);
		content.setAttribute("aria-hidden", isExpanded);

		const text = button.querySelector("[data-button-text]");
		this.updateText(text, isExpanded);

		if (!isExpanded) {
			content.style.height = `${content.children[0].offsetHeight}px`;
			this.enableContentLinks(content);
		} else {
			content.style.height = this.closedHeight();
			this.disableContentLinks(content);
		}

		isExpanded = !isExpanded;
	}

	linksInContent(el) {
		return [
			...el.querySelectorAll("a[href]:not([disabled]), button:not([disabled]"),
		];
	}

	disableContentLinks(el) {
		this.linksInContent(el).forEach((el) => {
			el.setAttribute("tabindex", -1);
		});
	}

	enableContentLinks(el) {
		this.linksInContent(el).forEach((el) => {
			el.setAttribute("tabindex", 0);
		});
	}

	setContentSection(el) {
		el.setAttribute("aria-hidden", true);
		el.style.overflowY = "hidden";
		el.style.height = this.closedHeight();
		this.disableContentLinks(el);
	}

	handleResize() {
		this.contentSections.forEach((el) => {
			if (el.getAttribute("aria-hidden") == "false") {
				el.style.height = `${el.children[0].offsetHeight}px`;
			}
		});
	}

	closedHeight() {
		if (this.isVariant) {
			return "5.5rem";
		}

		return 0;
	}

	updateText(textEl, isExpanded) {
		// Basic accordions will not update text
		if (textEl === null) {
			return;
		}

		if (!isExpanded) {
			textEl.textContent = "Read less";
			return;
		}

		textEl.textContent = "Read more";
		return;
	}
}

export default Accordion;
