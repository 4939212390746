import bodyScrollLock from "../helpers/bodyScrollLock";
import resize, { minTablet, isDesktop } from "../helpers/resize";
import trapFocus from "../helpers/trapFocus";

class EventsFilter {
	constructor(el) {
		this.el = el;
		this.button = el.querySelector("[data-events-filter-header]");
		this.content = el.querySelector("[data-events-filter-content]");

		this.header = document.querySelector("[data-header]");
		if (isDesktop()) {
			this.headerHeight = this.header.dataset.height;
		} else {
			this.headerHeight = this.header.querySelector("nav").offsetHeight;
		}

		this.isLocked = false;
		this.content.setAttribute("hidden", "true");

		this.onResize();
		resize(this.onResize.bind(this));
		this.bindTrapFocus = this.trapFocusInMenu.bind(this);

		// Remove accordion behaviour for non-mobile devices
		if (!minTablet()) {
			this.button.addEventListener("click", this.onClick.bind(this));
		}
	}

	openMenu() {
		this.content.hidden = false;
		document.body.classList.add("is-event-filter-open");
		this.el.addEventListener("keydown", this.bindTrapFocus);

		this.button.setAttribute("aria-expanded", true);

		if (!isDesktop()) {
			bodyScrollLock(true);
			this.isLocked = true;
		}

		setTimeout(() => {
			this.content.classList.add("is-visible");
			this.open = true;
		}, 10);
	}

	closeMenu() {
		this.content.classList.remove("is-visible");
		this.button.setAttribute("aria-expanded", false);

		if (this.isLocked) {
			bodyScrollLock(false);
			this.isLocked = false;
		}

		setTimeout(() => {
			this.content.hidden = true;
			document.body.classList.remove("is-event-filter-open");
			this.el.removeEventListener("keydown", this.bindTrapFocus);
			this.open = false;
		}, 200);
	}

	onClick() {
		if (this.content.hidden === true) {
			this.openMenu();
		} else {
			this.closeMenu();
		}
	}

	toggleActiveClass(header) {
		if (header.classList.contains("is-expanded")) {
			header.classList.remove("is-expanded");
		} else {
			header.classList.add("is-expanded");
		}
	}

	trapFocusInMenu(e) {
		const escIsPressed = e.keyCode === 27 || e.key === "Esc";

		trapFocus(e, this.el);

		if (escIsPressed) {
			this.closeMenu(e);
		}
	}

	onResize() {
		if (minTablet()) {
			this.content.hidden = false;
			this.pinTop();
		} else {
			this.closeMenu();
			if (this.el.style.top) {
				this.el.style.removeProperty("top");
			}
		}
	}

	pinTop() {
		this.el.style.top = `${this.headerHeight}px`;
	}
}

export default EventsFilter;
