import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import resize, { isDesktop } from "../helpers/resize";

gsap.registerPlugin(ScrollTrigger);

class Header {
	constructor(el) {
		this.el = el;
		this.bannerAd = this.el.querySelector("[data-ad]");
		this.topNav = this.el.querySelector("[data-top-nav]");
		this.topNavContent = this.el.querySelector("[data-top-nav-content]");
		this.searchBarExpandButtonAndMenu = this.el.querySelector(
			"[data-search-bar-expand-button-and-menu]",
		);
		this.searchBarExpandButtonDiv = this.el.querySelector(
			"[data-search-bar-expand-button-div]",
		);
		this.searchBarExpandButton = this.el.querySelector(
			"[data-search-bar-expand-button]",
		);
		this.searchBarExpanded = this.el.querySelector(
			"[data-search-bar-expanded]",
		);
		this.searchBarShrinkButton = this.el.querySelector(
			"[data-search-bar-shrink-button]",
		);
		this.tl = null;
		this.tl2 = null;

		this.initTimeline1();

		if (isDesktop()) {
			this.initTimeline2();
		}

		resize(this.handleResize.bind(this));

		this.searchBarExpandButton.addEventListener(
			"click",
			this.handleSearchBarChange.bind(this),
		);
		this.searchBarShrinkButton.addEventListener(
			"click",
			this.handleSearchBarChange.bind(this),
		);
	}

	handleSearchBarChange() {
		// Log output, simplify snippet to only include navbar cases
		const searchBarExpandButtonAndMenuFocusEls =
			this.searchBarExpandButtonAndMenu.querySelectorAll(
				"button:not([disabled]), a, input:not([disabled])",
			);
		const searchBarExpandedFocusEls = this.searchBarExpanded.querySelectorAll(
			"button:not([disabled]), a, input:not([disabled])",
		);

		if (
			this.searchBarExpandButtonAndMenu.classList.contains(
				"g-header__searchbar--hidden",
			)
		) {
			this.searchBarExpandButtonAndMenu.classList.remove(
				"g-header__searchbar--hidden",
			);
			this.searchBarExpanded.classList.add("g-header__searchbar--hidden");

			// Change tabindex
			for (const el of searchBarExpandButtonAndMenuFocusEls) {
				el.tabIndex = 0;
			}
			for (const el of searchBarExpandedFocusEls) {
				el.tabIndex = -1;
			}
		} else {
			this.searchBarExpandButtonAndMenu.classList.add(
				"g-header__searchbar--hidden",
			);
			this.searchBarExpanded.classList.remove("g-header__searchbar--hidden");

			// Change tabindex
			for (const el of searchBarExpandedFocusEls) {
				el.tabIndex = 0;
			}
			for (const el of searchBarExpandButtonAndMenuFocusEls) {
				el.tabIndex = -1;
			}
		}
	}

	fixHeader() {
		const topPos = this.bannerAd ? `${this.bannerAd.clientHeight * -1}px` : 0;
		this.el.style.position = "sticky";
		this.el.style.top = topPos;
	}

	initTimeline1() {
		this.tl = gsap.timeline({
			scrollTrigger: {
				trigger: this.el,
				start: "top top",
				toggleActions: "play none none reverse",
				onEnter: () => {
					this.fixHeader();
				},
			},
		});
	}

	getOffsetHeight() {
		return this.topNav.clientHeight * -1;
	}

	initTimeline2() {
		this.el.dataset.height =
			this.el.clientHeight +
			this.getOffsetHeight() -
			this.bannerAd.clientHeight;

		this.tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: "#main",
				start: "top -300px",
				toggleActions: "play none none reverse",
			},
			duration: 0.25,
		});
		this.tl2.to(
			this.topNav,
			{
				scale: 0,
				duration: 0.25,
				ease: "sine.in",
			},
			0,
		);
		this.tl2.to(
			this.el,
			{
				y: this.getOffsetHeight.bind(this),
				duration: 0.25,
				ease: "sine.in",
			},
			0,
		);
		this.tl2.to(
			this.searchBarExpandButtonDiv,
			{
				scaleX: 1,
				width: "auto",
				marginLeft: "20px",
				ease: "sine.in",
				duration: 0.25,
			},
			0,
		);
		this.tl2.to(
			this.searchBarExpandButton,
			{
				duration: 0.25,
				onComplete: () => {
					this.searchBarExpandButton.tabIndex = 0;
				},
				onReverseComplete: () => {
					this.searchBarExpandButton.tabIndex = -1;
				},
			},
			0,
		);
	}

	destroyTimeline2() {
		this.tl2.scrollTrigger.kill();
		this.tl2.kill();
		this.tl2 = null;
	}

	handleResize() {
		if (isDesktop() && !this.tl2) {
			this.initTimeline2();
		} else if (!isDesktop() && this.tl2) {
			this.destroyTimeline2();
		}
	}
}

export default Header;
