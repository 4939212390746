import bodyScrollLock from "../helpers/bodyScrollLock";
import resize from "../helpers/resize";
import { trapFocus, visibleLinks } from "../helpers/trapFocus";

const options = {
	rootMargin: "0px",
	threshold: 1,
};

class JumpNav {
	constructor(el) {
		this.el = el;
		this.list = el.querySelector("[data-list]");
		this.button = this.el.querySelector('[data-btn="jump-nav-open"]');
		this.buttonText = this.button.querySelector("[data-btn-text]");
		this.closeButton = this.el.querySelector("[data-close-btn]");
		this.content = this.el.querySelector("[data-content]");
		this.headings = [...document.querySelectorAll("h2")];
		this.title = this.el.querySelector("[data-title]");

		this.filteredHeadings = this.headings.filter((el) => el.textContent.length);
		// Don't include lightbox/modal h2s
		this.filteredHeadings = this.filteredHeadings.filter(this.isVisible);
		// Don't include team member profile block
		this.filteredHeadings = this.filteredHeadings.filter(
			(el) => !el.dataset.jumpNavExclude,
		);

		this.isLocked = false;
		this.observer = new IntersectionObserver(
			(entries) => this.onIntersect(entries, this.el),
			options,
		);

		/* Events */
		this.bindTrapFocus = this.trapFocusInMenu.bind(this);

		this.init();
		this.button.addEventListener("click", this.onClick.bind(this));
		resize(this.onResize.bind(this));
	}

	doClose() {
		return new Promise((resolve) => {
			this.closeMenu();
			resolve("close");
		});
	}

	async removeNavAfterClose() {
		await this.doClose();
	}

	onIntersect(entries, el) {
		const topicEl = el.querySelector("[data-topic]");
		const title = el.querySelector("[data-title]");

		entries.forEach((entry) => {
			const { target, isIntersecting, intersectionRatio } = entry;

			if (isIntersecting && intersectionRatio >= 1) {
				title.setAttribute("style", "");
				topicEl.innerText = target.dataset.jumpNavTitle
					? target.dataset.jumpNavTitle
					: target.innerText;
			}
		});
	}

	setHeadingAttributes(el, index) {
		el.setAttribute("id", this.headingObjects()[index].url);
		el.setAttribute("tabindex", "-1");
		el.setAttribute("data-heading", index);
	}

	init() {
		this.list.innerHTML = this.createListContent();
		this.el.hidden = false;
		document.body.classList.add("has-jump-nav");

		this.filteredHeadings.forEach((el, index) => {
			this.setHeadingAttributes(el, index);
			this.observer.observe(el);
		});

		this.content.addEventListener("click", this.onItemClick.bind(this));

		if (this.isDesktop()) {
			return;
		}

		this.content.setAttribute("hidden", "true");
		this.title.style.display = "none";

		if (this.closeButton) {
			this.closeButton.addEventListener(
				"click",
				this.removeNavAfterClose.bind(this),
			);
		}
	}

	isDesktop() {
		return window.innerWidth >= 1024;
	}

	isVisible(el) {
		return (
			el.offsetWidth > 0 ||
			el.offsetHeight > 0 ||
			el.getClientRects().length > 0
		);
	}

	setCurrentLinkStyles(i) {
		const links = visibleLinks(this.list);

		links.forEach((el, index) => {
			if (index === i) {
				el.classList.add("is-current");
			} else {
				el.classList.remove("is-current");
			}
		});
	}

	scrollToTarget(scrollTarget) {
		const headerHeight = document.querySelector("[data-header]").clientHeight;
		const offsetHeight = headerHeight + 100;
		const y =
			window.pageYOffset +
			(scrollTarget.getBoundingClientRect().top - offsetHeight);

		window.scrollTo({
			top: y,
			left: 0,
			behavior: "smooth",
		});

		setTimeout(() => scrollTarget.focus(), 1000);
	}

	onItemClick(e) {
		// this needs to be above the preventDefault() as it checks to see if the element clicked has a data-id attribute, if it doesn't then this click event will return out and the link will act like a normal link
		if (!e.target.dataset.id) return;

		e.preventDefault();

		const i = parseInt(e.target.dataset.id);
		const scrollTarget = this.filteredHeadings[i];

		this.setCurrentLinkStyles(i);

		if (scrollTarget) {
			if (!this.isDesktop()) {
				this.closeMenu();
			}
			this.scrollToTarget(scrollTarget);
		}
	}

	heightFromTop() {
		const { top } = this.content.getBoundingClientRect();
		return window.innerHeight - top;
	}

	setScrollableHeight() {
		if (this.isDesktop()) {
			this.content.style.height = "auto";
			this.content.style.maxHeight = `${this.heightFromTop()}px`;
		} else {
			this.content.style.height = `${this.heightFromTop()}px`;
			this.content.style.maxHeight = "none";
		}
	}

	onResize() {
		if (!this.isDesktop()) {
			if (this.content.hidden) return;

			this.closeMenu();
			return;
		} else {
			this.content.hidden = false;
		}

		this.setScrollableHeight();
	}

	openMenu() {
		this.content.hidden = false;
		document.body.classList.add("is-jump-nav-open");
		this.el.addEventListener("keydown", this.bindTrapFocus);
		this.setScrollableHeight();
		this.button.setAttribute("aria-expanded", true);

		if (!this.isDesktop()) {
			bodyScrollLock(true);
			this.isLocked = true;
			this.buttonText.innerText = "Close";
		}

		setTimeout(() => {
			this.content.classList.add("is-visible");
			this.open = true;
		}, 10);
	}

	closeMenu() {
		this.content.classList.remove("is-visible");
		this.buttonText.innerText = "Jump to";
		this.button.setAttribute("aria-expanded", false);

		if (this.isLocked) {
			bodyScrollLock(false);
			this.isLocked = false;
		}

		setTimeout(() => {
			this.content.hidden = true;
			document.body.classList.remove("is-jump-nav-open");
			this.el.removeEventListener("keydown", this.bindTrapFocus);
			this.open = false;
		}, 200);
	}

	onClick() {
		if (this.content.hidden === true) {
			this.openMenu();
		} else {
			this.closeMenu();
		}
	}

	trapFocusInMenu(e) {
		const escIsPressed = e.keyCode === 27 || e.key === "Esc";

		trapFocus(e, this.el);

		if (escIsPressed) {
			this.closeMenu(e);
		}
	}

	getHeadingID(el) {
		if (el.id) return el.id;

		return `${el.textContent.toLowerCase().split(" ").join("-")}`;
	}

	headingObjects() {
		return this.filteredHeadings.map((el) => {
			const url = this.getHeadingID(el);

			return {
				title: el.dataset.jumpNavTitle
					? el.dataset.jumpNavTitle
					: el.textContent,
				url,
			};
		});
	}

	createListContent() {
		return this.headingObjects()
			.map((el, index) => {
				return `<li class="c-jump-nav-horizontal__item"><a href="#${el.url}" class="c-jump-nav-horizontal__link block relative text-body mb-3 lg:mb-0 lg:text-white" data-id="${index}" >${el.title}</a></li>`;
			})
			.join("");
	}
}

export default JumpNav;
