import bodyScrollLock from "../helpers/bodyScrollLock";
import { trapFocus } from "../helpers/trapFocus";

class LightboxForm {
	constructor(el) {
		this.btn = el.querySelector("[data-btn]");
		this.lightbox = el.querySelector(`#${this.btn.dataset.btn}`);
		this.init();
	}

	init() {
		document.body.appendChild(this.lightbox);
		this.lightbox.hidden = true;
		this.lightbox.classList.add("hidden");
		this.btn.addEventListener("click", this.open.bind(this));

		this.closeButtons = [...this.lightbox.querySelectorAll("[data-close]")];

		this.closeButtons.forEach((el) => {
			el.addEventListener("click", this.close.bind(this));
		});

		this.attachKeyDownEvents();
	}

	open(e) {
		e.preventDefault();
		bodyScrollLock(true);
		this.lightbox.hidden = false;
		this.lightbox.classList.remove("hidden");
		this.btn.setAttribute("aria-expanded", true);
		this.lightbox.setAttribute("aria-hidden", false);

		setTimeout(() => {
			this.lightbox.classList.add("is-active");
			this.lightbox.style.opacity = 1;
			this.closeButtons[0].focus();
		}, 100);
	}

	attachKeyDownEvents() {
		this.lightbox.addEventListener("keydown", (e) => {
			const escIsPressed = e.keyCode === 27 || e.key === "Esc";

			trapFocus(e, this.lightbox);

			if (escIsPressed) {
				this.close(e);
			}
		});
	}

	close(e) {
		e.preventDefault();
		e.stopPropagation();
		this.lightbox.style.opacity = 0;
		bodyScrollLock(false);

		setTimeout(() => {
			this.btn.setAttribute("aria-expanded", false);
			this.lightbox.setAttribute("aria-hidden", true);
			this.lightbox.hidden = true;
			this.btn.focus();
		}, 300);
	}
}

export default LightboxForm;
